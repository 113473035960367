<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="/img/logo-dialog.405b5032.svg" alt="" />
    </b-link>
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">
        <!-- form -->
        <validation-observer ref="registerForm">
          <b-form class="auth-register-form" @submit.prevent="validationForm">
                              <b-row>
                    <b-col>
                      <b-form-group :label="$t('First Name*')" label-for="firstName">
                        <validation-provider
                          #default="{ errors }"
                          name="firstName"
                          rules="required"
                        >
                          <b-form-input
                            id="firstName"
                            v-model="firstName"
                            :state="errors.length > 0 ? false : null"
                            name="register-firstName"
                            :placeholder="$t('First Name')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- last name -->
                    <b-col>
                      <b-form-group :label="$t('Last Name*')" label-for="lastName">
                        <validation-provider
                          #default="{ errors }"
                          name="lastName"
                          rules="required"
                        >
                          <b-form-input
                            id="lastName"
                            v-model="lastName"
                            :state="errors.length > 0 ? false : null"
                            name="register-lastName"
                            :placeholder="$t('Last Name')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group :label="$t('Email*')" label-for="email"> </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-input-group>
                    <b-form-input
                      id="email"
                      v-model="regEmail"
                      :state="errors.length > 0 ? false : null"
                      name="register-email"
                      placeholder="example@domain.com"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group :label="$t('Company*')" label-for="empresa">
                </b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Empresa"
                  rules="required"
                >
                  <b-form-input
                    id="empresa"
                    v-model="company"
                    :state="errors.length > 0 ? false : null"
                    name="register-email"
                    :placeholder="$t('Company')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- password -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('Password*')" label-for="password">
                </b-form-group>

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      name="password"
                      ref="password"
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <!-- re password -->
              <b-col cols="12" md="6">
                <b-form-group :label="$t('Confirm password*')" label-for="repassword">
                </b-form-group>

                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      data-vv-as="repassword"
                      id="repassword"
                      v-model="repassword"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
  
              <b-col cols="12" md="6">
                <b-form-group label="Mobile Number*" label-for="Telefone">
                </b-form-group>
                <VuePhoneNumberInput
                  required
                  v-model="phone_number"
                  @update="onUpdate"
                  default-country-code="PT"
                />
              </b-col>
              <b-col cols="12" md="6">
               <b-form-group :label="$t('Language*')" label-for="language">
                        <validation-provider
                          #default="{ errors }"
                          name="language"
                          rules="required"
                        >
                      <v-select
                          class="form-control-merge"
                          :placeholder="$t('Language')"
                          :options="languages"
                          v-model="selectedLanguage"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
              <b-col cols="12" class="mt-2">
                <b-row>
                  <b-col cols="12" md="8">
                    <!-- checkbox -->
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="status"
                      name="checkbox-1"
                      required
                    >
                      {{ $t("I have read and agree with the ") }}
                      <b-link href="https://dialog-static-dev.s3.eu-west-1.amazonaws.com/pdf/mercatus.pdf"><strong>{{$t("terms and conditions ")}}</strong></b-link> {{$t("of use and")}}
                      <b-link href="https://dialog-static-dev.s3.eu-west-1.amazonaws.com/pdf/mercatus.pdf"><strong></strong></b-link>
                    </b-form-checkbox>
                  </b-col>
                  <p></p>
                  <b-col cols="12" md="4" class="mt-2 mt-md-0">
                    <!-- submit button -->
                    <div class="register-btn">
                      <b-button class="btn-register" type="submit">
                        {{$t("Register")}}
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
      <b-card-text class="login-btn text-center">
        <b-link :to="{ name: 'auth-login' }">
          <span>{{$t("Login")}}</span>
        </b-link>
      </b-card-text>

      <!-- /Register v1 -->
    </div>
    <a
      class="mercatus-logo"
      href="https://cooling.mercatus.pt/pt/home"
      target="_blank"
    >
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="" />
    </a>
    <b-modal
      id="permissions-modal"
      hide-footer
      
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title> {{$t('Register new user')}} </template>
      <div class="d-block text-center">
        <h3>{{$t("You don't have permissions to register. Sorry!")}}</h3>
      </div>
    </b-modal>
    <b-modal
      id="subscription-modal"
      hide-footer
      
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #modal-title> {{$t('Finish Subscription')}} </template>
      <b-card class="content-section paypal-container">
          <b-card-body>
            <span v-if="showPaypal" class="payment-method-title">{{$t("Payment Method")}}</span>
          <div id="paypal-button-container"></div>
          </b-card-body>
      </b-card>
      <b-button class="mt-3" block @click="validationForm()"
        >{{$t('Subscribe')}}</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BCard,
  BLink,
  BCardTitle,
  BCardText,
  BForm,
  BButton,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import CostLogo from "@core/layouts/components/Logo.vue";
import { required, email, password } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import { loadScript } from "@paypal/paypal-js";

export default {
  components: {
    CostLogo,
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect,
    VuePhoneNumberInput,
    ToastificationContent
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      currentDeviceMacNumber: '',
      showPaypal: false,
      isAdmin: false,
      allowedToRegister: false,
      country: "",
      regEmail: "",
      password: "",
      repassword: "",
      status: "",
      company: "",
      company_position: "",
      phone_number: "",
      country_code: "",
      firstName: "",
      lastName: "",
      languages:
        [
          {label:"English", value:"en"},
          {label:"Portuguese", value:"pt"},
          {label:"French", value:"fr"}
        ],
      selectedLanguage:"",

      // validation rules
      required,
      email,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    plan_id(){
      return this.$store.state.plans.plan_id ? this.$store.state.plans.plan_id : 'P-41D537154X839454YMRTAOOA'
    }
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then((success) => {
        if (success) {
            this.submitForm();
        }
      });
    },
    submitForm() {
      let data = {
        email: this.regEmail,
        password: this.password,
        repassword: this.repassword,
        country: this.country,
        company: this.company,
        country_code: this.country_code,
        phone_number: this.phone_number.toLowerCase().split(" ").join(""),
        first_name: this.firstName,
        last_name: this.lastName,
        default_language: this.selectedLanguage.value
      };
      this.$store
        .dispatch("users/postRegisterUser", { context: this, data: data })
        .then((sucess) => {
          if (sucess) {
            let title = this.$t("Confirm Email");
            let text = this.$t("Please, confirm the email sent to activate your account");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: title,
                icon: "CheckIcon",
                variant: "success",
                text: text,
              },
            });
            if (this.isAdmin){
              this.openModalSubscription()
            }
            else
            {
              this.$router.push({ name: "auth-login" , query:{token:this.$route.query.token}});
            }            
          }
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Invalid",
              icon: "AlertTriangleIcon",
              text: Object.values(error.response.data)[0],
            },
          });
        });
    },
    onUpdate(payload) {
      this.country_code = payload.countryCallingCode;
      this.country = payload.countryCode;
    },
    validateRegisterToken(token) {
      let devicesEndpoint = `${process.env.VUE_APP_API_URL}api/devices/`;
      this.$http
        .post(`${devicesEndpoint}validate-token/`, { token: token })
        .then((success) => {
          if (success.status === 200) {
            this.allowedToRegister = true;
            this.isAdmin = success.data.is_owner
            this.currentDeviceMacNumber = success.data.mac_number
          }
        })
        .catch((error) => {
          this.$bvModal.show("permissions-modal");
          return false;
        });
    },
    closePermissionsModal() {
      this.$router.push({ name: "auth-login" });
      this.$bvModal.hide("permissions-modal");
    },
    openModalPaypal() {
      this.$bvModal.show("subscription-modal");
    },
    closeSubscriptionsModal() {
      this.$bvModal.hide("subscription-modal");
    },
    loadPaypal(quantity, custom_ids, plan_id, notificationFunc, errorNotificationFunc){
      loadScript({ "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID, "intent":"subscription", "vault":true})
      .then((paypal) => {
          paypal.Buttons({
            style: {
            layout:  'vertical',
            shape:   'pill',
            label:   'paypal',
          },
          createSubscription: function(data, actions) {
            return actions.subscription.create({
            'plan_id': plan_id,
            "quantity": quantity,
            "custom_id": custom_ids,
            });
          },
          onApprove: function() {
            notificationFunc();
          }
        }).render('#paypal-button-container')
      })
      .catch((error) => {
        errorNotificationFunc();
      });
    },
    showNotification(){
      this.$toast({
            component: ToastificationContent,
            props: {
              icon: "CheckIcon",
              variant: "success",
              title: "Subscription Created",
              },
            });
      this.$router.push({ name: "auth-login" , query:{token:this.$route.query.token}});
    },
    errorNotification(){
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Subscription Failed',
                icon: 'AlertTriangleIcon',    
                variant: 'danger'
                },
            })
    },
    openModalSubscription(){
      this.showPaypal=true;
      this.$bvModal.show("subscription-modal");
      this.clearRender()
      let custom_ids = this.currentDeviceMacNumber
      let quantity = 1
      console.log(quantity, custom_ids, this.plan_id)
      this.loadPaypal(quantity, custom_ids, this.plan_id, this.showNotification, this.errorNotification)
    },
    clearRender() {
      const div = document.getElementById("paypal-button-container");
      if (div) {
        div.innerHTML = '';
      }
      this.showPaypal=false
    },
  },
  mounted() {
    this.validateRegisterToken(this.$route.query.token);
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/base/pages/page-home.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.plan-payment-row{
  margin-left: 0px;
  margin-right: 0px;
}

.paypal-container{
  background-color: #fff !important;
  border-left: solid 1px rgb(237, 240, 245);
  border-right: solid 1px rgb(237, 240, 245);
  border-bottom: solid 1px rgb(237, 240, 245);
  border-radius: 0px;
}

.remove-bottom-margin{
  margin-bottom: 0px !important;
}

.badge-processing {
    width: 100px;
    border-radius: 30px;
    background-color: #000 !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.badge-no-plan {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(64, 80, 130) !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.badge-trial {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(47, 121, 187) !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.badge-premium {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(237, 240, 245) !important;
    color: rgb(91, 91, 91) !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.payment-method-title{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(47, 121, 187);
  font-weight: bold;
  font-size: 25px;
}

@media (max-width: 2330px) {
  .payment-method-title{
    font-size: 20px;
  }
}
</style>